<template>
  <div>

    <div v-if='is_authenticated'>

      <div v-if='!permissionLoad'>
        <AppSidebar v-if='!isIframe'/>
        <AppHeaderIframe v-if='isIframe'/>
        <div class="wrapper wrapper-custom d-flex flex-column bg-light" :class="{'min-vh-100':!isIframe}">
          <AppHeader v-if='!isIframe'/>
          <div class="body flex-grow-1 px-3">
            <CContainer lg>
              <router-view v-slot="{ Component }">
                <transition name="fade" mode="out-in">
                  <component :is="Component" />
                </transition>
              </router-view>
            </CContainer>
          </div>
          <AppFooter v-if='!isIframe'/>
        </div>

        <div
          class="modal-window modal-window__trade modal-window__dark modal-window_statement"
          v-if="modal">
          <div class="modal-window__background" @click="closeModal"></div>
          <div>
            <a @click="closeModal" title="Close" class="modal-close">{{ $t('bo.close') }}</a>
            <div style="width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;" v-if='componentReload'>
              <CSpinner size="lg"/>
            </div>
            <div class="modal-window__container" v-else-if="data.type == 'transfer_in' || data.type == 'transfer_out'">
              <h1 class="blue" v-if="data.type == 'transfer_in'">{{ $t('bo.transferIn') }}</h1>
              <h1 class="blue" v-else>{{ $t('bo.transferOut') }}</h1>
              <hr class="solid">
              <div style="margin-top: 10px;">
                <p>{{ $t('bo.refNo') }}: {{ data.ref_no }}</p><br>
                <p>{{ $t('bo.createdAt') }}: {{ moment(data.created_at).format('DD/MM/YYYY HH:mm:ss') }}</p><br>
                <div v-if="data.type == 'transfer_in'">
                  <p>{{ $t('bo.sender') }}: {{ data.sender_user_id == -1 ? $t('bo.system') : (data.sender_user_id == 0 ? $t('bo.admin') : data.sender_user.username) }}</p><br>
                </div>
                <div v-else>
                  <p>{{ $t('bo.receiver') }}: {{ data.receiver_user.username }}</p><br>
                </div>
                <p>{{ $t('bo.numOfLots') }}: {{ generalNumberFormat(data.num_of_lot, 1) }}</p><br>
                <p>{{ $t('bo.pricePerLot') }}: {{ generalNumberFormat(data.price_per_lot) }}</p><br>
                <p>{{ $t('bo.totalAmount') }}: {{ generalNumberFormat(data.num_of_lot * data.price_per_lot) }}</p><br>
              </div>
            </div>

            <div class="modal-window__container" v-else>
              <h1 class="green" v-if="data.type == 'buy'">{{ $t('bo.buy') }}</h1>
              <h1 class="red" v-else-if="data.type == 'sell'">{{ $t('bo.sell') }}</h1>
              <h1 class="purple" v-else-if="data.type == 'affiliate_sales'">{{ $t('bo.affiliateSales') }}</h1>
              <h1 class="purple" v-else-if="data.type == 'affiliate'">{{ $t('bo.affiliate') }}</h1>
              <h1 class="red" v-else-if="data.type == 'marketplace'">{{ $t('bo.postingDetail') }}</h1>

              <hr class="solid">
              <div style="margin-top: 10px;">
                <template v-if="data.type != 'marketplace' && data.user_id != data.receiver_user_id">
                  <p>{{ $t('bo.status') }}: {{ data.status_string }}</p><br>
                </template>
                <p>{{ $t('bo.refNo') }}: {{ data.ref_no }}</p><br>
                <p>{{ $t('bo.createdAt') }}: {{ moment(data.created_at).format('DD/MM/YYYY HH:mm:ss') }}</p><br>
                <template v-if="data.type != 'marketplace' && data.user_id != data.receiver_user_id">
                  <p>{{ $t('bo.receivedAt') }}: {{ data.received_at ? moment(data.received_at).format('DD/MM/YYYY HH:mm:ss') : '-' }}</p><br>
                </template>
                <p>{{ data.type == 'affiliate_sales' || data.type == 'affiliate' ? $t('bo.sender') : $t('bo.username') }}: {{ data.user_id == data.receiver_user_id ? $t('bo.admin') : data.user.username }}</p><br>
                
                <div v-if="data.type == 'affiliate_sales' || data.type == 'affiliate'">
                  <template v-if="data.user_id != data.receiver_user_id">
                    <p>{{ $t('bo.referrer') }}: {{ data.receiver_user.referral_username }}</p><br>
                  </template>
                  <p>{{ $t('bo.receiver') }}: {{ data.receiver_user.username }}</p><br>
                </div>
                <p>{{ data.user_id != data.receiver_user_id ? $t('bo.numOfLots') : $t('bo.affiliateSales') }}: {{ generalNumberFormat(data.num_of_lot, 1) }}</p><br>
                <template v-if="(data.type == 'buy' || data.type == 'affiliate' || data.type == 'affiliate_sales' || data.type == 'sell') && data.user_id != data.receiver_user_id">
                  <p>{{ $t('bo.totalAmount') }}: {{ generalNumberFormat(data.num_of_lot * (data.type == 'affiliate' || (data.type == 'affiliate_sales' && getUserId != data.user_id && getUserId != data.receiver_user_id) ? data.affiliate_price_per_lot : data.price_per_lot)) }}</p><br>
                </template>
                <template v-if="data.user_id != data.receiver_user_id">
                  <p>{{ $t('bo.pricePerLot') }}: {{ generalNumberFormat(data.type == 'affiliate' || (data.type == 'affiliate_sales' && getUserId != data.user_id && getUserId != data.receiver_user_id) ? data.affiliate_price_per_lot : data.price_per_lot) }}</p><br>
                </template>
                <template v-if="data.type == 'marketplace'">
                  <p>{{ $t('bo.modeOfContact') }}: {{ data.social_media_name ?? '-' }}</p><br>
                  <p>{{ $t('bo.contactDetails') }}: {{ data.social_media_detail ?? '-' }}</p><br>
                </template>
                <template v-if="data.type == 'sell' || data.type == 'marketplace'">
                  <p>{{ $t('bo.walletAddress') }}: {{ data.wallet_address }}</p><br>
                </template>
                <template v-if="data.type != 'affiliate' && data.type != 'marketplace' && data.user_id != data.receiver_user_id && (getUserId == data.user_id || getUserId == data.receiver_user_id)">
                  <p>{{ $t('bo.transactionId') }}: {{ data.transaction_id ?? '-' }}</p>
                </template>
              </div>
            </div>
          </div>
        </div>

      </div>

      <AppLoader v-else />

    </div>
    <div v-else>
      <body class="header-fixed main">
        <div class="wrapper">
          <AppHeaderSec />
          <router-view />
          <AppFooterSec />
        </div>
        <a id="scroll-top"></a>
      </body>
    </div>

  </div>
</template>
<script>
import { computed, watch, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useToast } from "vue-toastification";
import { useRoute, useRouter } from 'vue-router';
import Cookies from 'js-cookie'
import { me } from "@/api/profile";
import { permission } from "@/api/role";
import { CContainer } from '@coreui/vue'
import AppLoader from '@/components/AppLoader.vue'
import AppFooter from '@/components/AppFooter.vue'
import AppFooterSec from '@/components/sub/Footer.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppHeaderIframe from '@/components/AppHeaderIframe.vue'
import AppHeaderSec from '@/components/sub/Header.vue'
import AppSidebar from '@/components/AppSidebar.vue'
import MainChartExample from '@/views/charts/MainChartExample'
import nav from '@/_nav.js'

export default {
  name: 'DefaultLayout',
  components: {
    AppFooter,
    AppFooterSec,
    AppHeader,
    AppHeaderIframe,
    AppHeaderSec,
    AppSidebar,
    AppLoader,
    CContainer,
    MainChartExample,
  },
  setup() {
    const toast = useToast();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    let roleId = ref(-1);
    let roleName = ref(null);
    let permissionLoad = ref(true);
    let isIframe = ref(false);

    watch(() => route.name, (dt) => {
      if (dt == 'Dashboard') {
        getMe()
      } 
    })

    onMounted(() => {
      if (!Cookies.get('mxTkn') || Cookies.get('mxTkn') == undefined) {
        router.push('/auth/logout')
        permissionLoad.value = false
      } else {
        store.commit('setAccessToken', Cookies.get('mxTkn'))
        getMe()
      }
    })

    async function getMe() {
      try {

        const res = await me()

        if (res.data.code == 200) {
          store.commit('setUserData', res.data.data)

          roleId.value = res.data.data.role_id
          if (res.data.data.role_id == 0) {
            roleName.value = 'Admin'
          } else if (res.data.data.role_id == 1) {
            roleName.value = 'User'
          }
          getPermission(roleId.value)
        } else {
          toast.error(res.data.message)
        }

      } catch (err) {
        toast.error(err)
      }
    }

    async function getPermission(roleId) {
      try {
        let params = {
          role_id: roleId
        }
        const res = await permission(params)

        if (res.data.code == 200) {
          store.commit('setRoleAccess', res.data.data)
          permissionLoad.value = false
        } else {
          toast.error(res.data.message)
        }

      } catch (err) {
        toast.error(err)
      }
    }

    return {
      toast,
      store,
      route,
      permissionLoad,
      isIframe,
      roleId,
      roleName,
      is_authenticated: computed(() => store.state.is_authenticated),
    }
  },
  data() {
    return {
      modal: false,
      data: {},
      componentReload: false,
    }
  },
  watch: {
    permissionLoad() {
      this.setPermission()
    }
  },
  computed: {
    getUserId() {
      try {
        return this.store.getters.getUserData.id;
      } catch (error) {
        return 0;
      }
    }
  },
  mounted() {
    this.emitter.on("setModalDisplay", (data) => {
      this.modal = true;
      this.setModalDisplay(data)
      setTimeout(() => {
        this.componentReload = false
      },1000)
    });
  },
  methods: {
    setModalDisplay(data) {
      this.data = data
    },
    closeModal() {
      this.modal = false
    },
    // Page permission
    setPermission() {
      nav.forEach((item, i) => {
        // Permission for User Investment
        if (item.code == 'investment' && (this.roleId != 1)) {
          item.visible = false
        }
        if (item.code == 'changePassword' && (this.roleId != 1)) {
          item.visible = false
        }
        if (item.code == 'report' && (this.roleId !== 1)) {
          item.visible = false
        }
        if (item.code == 'groupIncentives' && (this.roleId !== 1)) {
          item.visible = false
        }


        // Permission for Admin Setting
        if (item.code == 'submission' && (this.roleId !== 0)) {
          item.visible = false
        }
        if (item.code == 'roi' && (this.roleId !== 0)) {
          item.visible = false
        }
        if (item.code == 'user' && (this.roleId !== 0)) {
          item.visible = false
        }
        if (item.code == 'adminReport' && (this.roleId !== 0)) {
          item.visible = false
        }
        if (item.code == 'system' && (this.roleId !== 0)) {
          item.visible = false
        }
        if (item.code == 'setting' && (this.roleId !== 0)) {
          item.visible = false
        }
        if (item.code == 'contract' && (this.roleId !== 0)) {
          item.visible = false
        }
        if (item.code == 'notification' && (this.roleId !== 0)) {
          item.visible = false
        } 
        if (item.code == 'adjustment' && (this.roleId !== 0)) {
          item.visible = false
        } 
        if (item.code == 'recycleBin' && (this.roleId !== 0)) {
          item.visible = false
        } 
        if (item.code == 'tier' && (this.roleId !== 0)) {
          item.visible = false
        } 
      });
    },
  }
}
</script>
