import store from '@/store'
import Web3 from 'web3'
import WalletConnectProvider from "@walletconnect/web3-provider"
import { useI18n } from "vue-i18n";
import {
  useToast
} from "vue-toastification"
import moment from 'moment'
import ToastMessage from '@/components/Toast';
const { VUE_APP_BASE_URL, VUE_APP_TRONLINK_URL } = process.env
import { TYPE } from "vue-toastification";

export default {
  data: function () {
    return {
      chainList: [{
        id: 1,
        name: 'Ethereum Mainnet',
        chain: 'ETH',
      },
      {
        id: 3,
        name: 'Ethereum Testnet Ropsten',
        chain: 'ETH',
      },
      {
        id: 4,
        name: 'Ethereum Testnet Rinkeby',
        chain: 'ETH',
      },
      {
        id: 42,
        name: 'Ethereum Testnet Kovan',
        chain: 'ETH',
      },
      {
        id: 5,
        name: 'Ethereum Testnet Görli',
        chain: 'ETH',
      },
      {
        id: 56,
        name: 'Binance Smart Chain Mainnet',
        chain: 'BSC',
      },
      {
        id: 86,
        name: 'Binance Smart Chain Mainnet(android)', // Defined outside nvm
        chain: 'BSC',
      },
      {
        id: 97,
        name: 'Binance Smart Chain Testnet',
        chain: 'BSC',
      },
      ],
      toast: useToast(),
      moment: moment,
      baseUrl: VUE_APP_BASE_URL,
      welcomeMessage: {
        title: 'Welcome to ACT Capital.',
        message: `Congratulations on joining our community!

                  We're delighted to welcome you as we collaboratively build a thriving platform together.
                  Please ensure timely submission of quotas to avoid asset freezing and forfeiture. Thank you for your cooperation`,
        titleCn: '欢迎来到ACT资本。',
        messageCn: `恭喜您迈出了进入我们社区的第一步！

                    我们很高兴您加入我们，让大家共同打造共创共赢共享的平台，一齐见证平台的进步与繁荣盛世。
                    
                    温馨提示客户们准时提交配额，以确保不会错失提交配额时段，因为延迟误时提交所需配额，将会导致该用户资产被冻结没收。`,
        titleVn: 'Chào mừng đến với ACT Capital.',
        messageVn: `Xin chúc mừng bạn đã tham gia cộng đồng của chúng tôi!

                    Chúng tôi rất vui mừng được chào đón bạn khi chúng ta cùng nhau hợp tác xây dựng một nền tảng phát triển mạnh mẽ. Vui lòng đảm bảo nộp hạn ngạch kịp thời để tránh bị đóng băng và tịch thu tài sản. Cảm ơn bạn đã hợp tác của bạn.`,
      },
      toastInterval: null
    }
  },
  beforeDestroy() {
    console.log('beforeDestroy');
    if (this.toastInterval) clearTimeout(this.toastInterval);
  },
  computed: {
    rolePermission() {
      return store.state.rlAccess
    },
    getTronlinkDeeplink() {
      return `tronlinkoutside://pull.activity?param=${encodeURIComponent(JSON.stringify({
        url: window.location.href,
        action: 'open',
        protocol: 'tronlink',
        version: '1.0',
      }))}`;
    },
    getTokenPocketDeeplink() {
      return `tpdapp://open?params=${encodeURIComponent(JSON.stringify({
        url: window.location.href,
        chain: 'Tron',
        source: '1.0',
      }))}`;
    },
    getTrustwalletDeeplink() {
      return `trust://${window.location.href}}`;
    },
    getMetamaskDeeplink() {
      return `dapp://${window.location.hostname}`;
    },
  },
  methods: {
    async walletConnect() {
      try {
        //  Create WalletConnect Provider
        const provider = new WalletConnectProvider({
          rpc: {
            1: "https://mainnet.infura.io/v3/",
            56: "https://bsc-dataseed.binance.org/",
          },
          infuraId: '4ec235cb31904878b523ec43e3f4f339'
        });

        //  Enable session (triggers QR Code modal)
        await provider.enable();

        //  Create Web3
        const instance = new Web3(provider);

        // Get necessary info on your node
        const networkId = await instance.eth.net.getId();
        const coinbase = await instance.eth.getCoinbase();
        const balance = 0;
        const networkData = this.chainList.find(function (e) {
          return networkId === e.id
        })
        const networkName = networkData.chain

        store.commit('setWalletConnect', 'WalletConnect')

        let storeWallet = [
          networkId,
          networkData,
          networkName,
          coinbase,
          balance
        ]

        store.commit('setWalletData', storeWallet)

      } catch (e) {

      }
    },
    async connectMetamask() {
      // // Check for web3 provider
      const {
        ethereum
      } = window;
      const {
        host,
        pathname
      } = window.location;

      try {
        // Ask to connect
        await ethereum.send('eth_requestAccounts');
        const instance = new Web3(ethereum)

        // Get necessary info on your node
        const networkId = await instance.eth.net.getId();
        const coinbase = await instance.eth.getCoinbase();
        const balance = 0;
        const networkData = this.chainList.find(function (e) {
          return networkId === e.id
        })
        const networkName = networkData.chain

        store.commit('setWalletConnect', 'MetaMask')

        let storeWallet = [
          networkId,
          networkData,
          networkName,
          coinbase,
          balance
        ]

        store.commit('setWalletData', storeWallet)

      } catch (error) {
        // User denied account access
        this.toast.error('User denied access')
        return;
      }
    },
    async connectTronlink() {
      if (window.tronWeb) {
        //checking if wallet injected
        if (window.tronWeb.ready) {
          let tempBalance = await this.tronGetBalance();

          if (!tempBalance.balance) {
            tempBalance.balance = 0;
          }

          store.commit('setWalletConnect', 'TronLink')

          let storeWallet = [
            null,
            null,
            'TRON',
            window.tronWeb.defaultAddress.base58,
            tempBalance.balance / 1000000
          ]

          store.commit('setWalletData', storeWallet)

        } else {
          //we have wallet but not logged in
          this.toast.error('Please Login on your Dapp to connect!')
        }
      } else {
        //wallet is not detected at all
        // this.toast.error(this.$t('bo.dappErrorOpen'))
        const $self = this;
        let toastTimeout = 5000;
        this.toast.error({
          component: ToastMessage,
          props: {
            message: this.$t('bo.dappErrorOpen'),
          },
          listeners: {
            click: () => {
              window.open(VUE_APP_TRONLINK_URL, '_blank');
            },
          },
        });

        if (this.toastInterval) clearTimeout(this.toastInterval);
        this.toastInterval = setTimeout(() => {
          window.location.href = $self.getTronlinkDeeplink;
          window.location.href = $self.getTokenPocketDeeplink;
          window.location.href = $self.getTrustwalletDeeplink;
          window.location.href = $self.getMetamaskDeeplink;
        }, toastTimeout);
      }
    },
    async tronGetBalance() {
      if (window.tronWeb && window.tronWeb.ready) {
        let walletBalances = await window.tronWeb.trx.getAccount(
          window.tronWeb.defaultAddress.base58
        );
        return walletBalances;
      } else {
        return 0;
      }
    },
    disconnectWallet() {
      store.commit('setWalletDisconnect')
    },
    elipsisAddress(address) {
      return address.substring(0, 8) + '...' + address.substring(address.length - 4)
    },
    generalNumberFormat(number = 0, decimal = 0) {
      return parseFloat(number).toFixed(decimal).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parseFloat(number).toFixed(0).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    },
    NumbersOnly(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      // if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
      if ((charCode >= 48 && charCode <= 57) || (charCode >= 96 && charCode <= 105)) {
        return true;
      } else {
        evt.preventDefault();
      }
    },
    NumbersWithMinus(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 45) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    minus(val1, val2) {
      return window.Decimal(Number(val1)).minus(Number(val2))
    },
    trimBalance(value) {
			const firstVal = value != null ? value.toString().split('.')[0] : '0'
			const secVal = value != null ? value.toString().split('.')[1] : ''

			return firstVal + (secVal == '' || secVal == null ? '' : '.' + secVal.substring(0, 4))
		},
    getWelcomeMessage(dt, lang) {
      var $self = this;
      return dt.map((e) => {
        if(e.notification.title == "register_title") {
          var temp = e;
          if (lang == 'cn') {
            temp.notification.title = $self.welcomeMessage.titleCn;
            temp.notification.message = $self.welcomeMessage.messageCn;
          } else if (lang == 'vn') {
            temp.notification.title = $self.welcomeMessage.titleVn;
            temp.notification.message = $self.welcomeMessage.messageVn;
          } else {
            temp.notification.title = $self.welcomeMessage.title;
            temp.notification.message = $self.welcomeMessage.message;
          }
          console.log(temp);
          return temp

        }
        return e;
      });
    },
    snakeToCamel(str) {
      return str.toLowerCase().replace(/([-_][a-z])/g, group =>
        group
          .toUpperCase()
          .replace('-', '')
          .replace('_', '')
          );
    }
  }
}
