<template>
  <CHeader position="sticky" class="mb-4" v-show='!base_modal_appear'>
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderNav class="d-none d-md-flex me-auto">
        <!-- <CNavItem>

        </CNavItem> -->
      </CHeaderNav>
      <CHeaderNav class="header__tools">
        <CNavItem class="header__balance" v-if='totalBalance'>
          <span>{{ $t('bo.balance') }}: {{ totalBalance }}</span>
        </CNavItem>
        <!-- Hide connect wallet -->
        <!-- <CDropdownItem @click='walletConnect()'>ConnectWallet</CDropdownItem> -->
        <!-- <CNavItem>
          <template v-for="color in [
            'light'
            ]" :key="color">
            <CDropdown variant="btn-group" v-if='wallet_connect == null'>
              <CDropdownToggle color="light"> {{ $t('bo.connectWallet') }}</CDropdownToggle>
              <CDropdownMenu>
                <CDropdownItem @click='connectMetamask()' v-if="!user_data.network_type || user_data.network_type == 'ethereum'" >MetaMask</CDropdownItem>
                <CDropdownItem @click='connectTronlink()' v-if="!user_data.network_type || user_data.network_type == 'tron'" >TronLink</CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
            <CDropdown variant="btn-group" v-else>
              <CDropdownToggle color="light">
                <span>{{ wallet_connect }}</span>
                <br>
                <span>{{ elipsisAddress(wallet_data['coinbase']) }}</span>
              </CDropdownToggle>
              <CDropdownMenu>
                <CDropdownItem @click='disconnectWallet()'>{{ $t('bo.disconnect') }} {{ wallet_connect }}</CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
          </template>
        </CNavItem> -->
        <!-- Hide connect wallet -->
        <div class="header__right ms-1">
          <CNavItem>
            <CDropdown variant="btn-group">
              <CDropdownToggle color="white"><i class="fi mr-2"
                  :class="`fi-${this.selectedLanguage}`"></i>
              </CDropdownToggle>
              <CDropdownMenu>
                <CDropdownItem @click="changeLanguage('us')">
                  <i class="fi fi-us mr-2"></i> English
                </CDropdownItem>
                <CDropdownItem @click="changeLanguage('cn')">
                  <i class="fi fi-cn mr-2"></i> Chinese
                </CDropdownItem>
                <CDropdownItem @click="changeLanguage('vn')">
                  <i class="fi fi-vn mr-2"></i> Vietnam
                </CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
          </CNavItem>
          <CNavItem>
            <CNavLink>
              <router-link class="dropdown-item"
                :to="user_data.role_id === 0 ? '/system/notification' : '/notification'">
                <CIcon class="mx-2" icon="cil-bell" size="lg" />
              </router-link>
            </CNavLink>
          </CNavItem>
          <AppHeaderDropdownAccnt />
        </div>
      </CHeaderNav>
    </CContainer>
    <!-- <CHeaderDivider />
    <CContainer fluid>
      <AppBreadcrumb />
    </CContainer> -->
  </CHeader>
</template>

<script>
import { useStore, mapState } from 'vuex'
import { useToast } from "vue-toastification";
import AppBreadcrumb from './AppBreadcrumb'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import { logo } from '@/assets/brand/logo'
import { claim } from "@/api/investment"
import { useRoute } from 'vue-router';

export default {
  name: 'AppHeader',
  components: {
    // AppBreadcrumb,
    AppHeaderDropdownAccnt,
  },
  setup() {
    const store = useStore()
    return {
      logo,
    }
  },
  data() {
    return {
      toast: useToast(),
      selectedLanguage: 'us',
      route: useRoute(),
    }
  },
  computed: {
    ...mapState([
      "language",
      'wallet_data',
      'wallet_connect',
      'user_data',
      'base_modal_appear'
    ]),
    totalBalance() {
      if (this.user_data == null || this.user_data == undefined || this.user_data.wallet == null) {
        return false
      }
      const wallet = this.user_data.wallet
      const activation_wallet = (wallet.activation_wallet == null ? 0 : wallet.activation_wallet)
      const rewards_wallet = (wallet.rewards_wallet == null ? 0 : wallet.rewards_wallet)
      const roi_income_wallet = (wallet.roi_income_wallet == null ? 0 : wallet.roi_income_wallet)
      return '$' + window.Decimal(activation_wallet).plus(rewards_wallet).plus(roi_income_wallet)
    },
  },
  mounted() {
    try {
      let lang = this.route.query.lang
      if (lang) {
        this.$store.dispatch('setLanguage', lang);
      }
    } catch (error) {
      
    }

    this.$i18n.locale = this.language
    this.selectedLanguage = this.language
  },
  methods: {
    changeLanguage(locale) {
      console.log(locale)
      this.$i18n.locale = locale
      this.$store.dispatch('setLanguage', locale);
      this.selectedLanguage = locale
    },
  }
}
</script>
