<template>
  <h1>{{message}}</h1>
</template>

<script>
export default {
  name: 'Toast',
  props: {
    message: {
        type: String,
        default: ''
    }
  }
}
</script>
