import { createStore } from 'vuex'
import Cookies from 'js-cookie'
import VuexPersistence from 'vuex-persist'
import router from '@/router'

const vuexLocal = new VuexPersistence({
  key: 'mxStre',
  storage: window.localStorage
})

export default createStore({
  state: {
    sidebarVisible: '',
    sidebarUnfoldable: false,
    access_token: null,
    id: null,
    type: null,
    role: 'visitor',
    user_data: null,
    is_authenticated: false,
    wallet_connect: null,
    language: 'us',
    wallet_data: {
      networkId: null,
      networkData: null,
      networkName: null,
      coinbase: null,
      balance: null,
    },
    rlAccess: [],
    trading_time: {},
    base_modal_appear: false,
    alreadySeeNotificationPopUp: null,
  },
  mutations: {
    setUserPayload(state, data) {
      state.access_token = data.token
      // set cookie token
      Cookies.set('mxTkn', state.access_token)

      setTimeout(() => {
        window.location.reload(true)
      }, 100)

    },
    setAlreadySeeNotificationPopUp(state) {
      // since coreUi have double reload bug, we do hackfix for this function
      if (state.alreadySeeNotificationPopUp == null)
        state.alreadySeeNotificationPopUp  = false
      else if (state.alreadySeeNotificationPopUp == false)
        state.alreadySeeNotificationPopUp  = true
    },
    setUserLogout(state) {
      state.access_token = null
      state.id = null
      state.type = null
      state.user_data = null
      state.role = 'visitor'
      state.is_authenticated = false
      // state.wallet_connect = null
      // state.wallet_data = {
      //   networkId: null,
      //   networkData: null,
      //   networkName: null,
      //   coinbase: null,
      //   balance: null,
      // }
      state.alreadySeeNotificationPopUp  = null

      // remove cookie token
      Cookies.remove('mxTkn')

      router.push('/')
    },
    setUserData(state, data) {
      state.id = data.id
      state.type = data.role_id

      if (data.role_id == 1) {
        state.role = 'user'
      } else if (data.role_id == 2) {
        state.role = 'client'
      } else {
        state.role = 'admin'
      }

      state.user_data = data;
      console.log(JSON.stringify(state.user_data));
      state.is_authenticated = true
    },
    setAccessToken(state, data) {
      state.access_token = data
    },
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    setWalletData(state, data) {
      let web3Copy = state.wallet_data
      web3Copy.networkId = data[0]
      web3Copy.networkData = data[1]
      web3Copy.networkName = data[2]
      web3Copy.coinbase = data[3]
      web3Copy.balance = parseInt(data[4], 10)
      state.wallet_data = web3Copy
    },
    setWalletConnect(state, data) {
      state.wallet_connect = data
    },
    setWalletDisconnect(state) {
      state.wallet_connect = null
      state.wallet_data = {
        networkId: null,
        networkData: null,
        networkName: null,
        coinbase: null,
        balance: null,
      }
      localStorage.removeItem('walletconnect')
    },
    setRoleAccess(state, data) {
      state.rlAccess = data
    },
    setLanguage(state, language) {
      state.language = language;
    },
    setBaseModal(state, data) {
      state.base_modal_appear = data;
    },
  },
  actions: {
    serverInit({ state }) {
      state.access_token = Cookies.get('mxTkn')
    },
    setLanguage({ commit }, language) {
      commit('setLanguage', language)
    }
  },
  getters: {
    getUserData(state) {
      return state.user_data;
    },
    getToken(state) {
      return state.access_token;
    },
    getWalletData(state) {
      return state.wallet_data
    },
    getWalletConnect(state) {
      return state.wallet_connect;
    },
    getRoleAccess(state) {
      return state.rlAccess
    },
    getBaseModal(state) {
      return state.base_modal_appear
    },
  },
  modules: {},
  plugins: [vuexLocal.plugin]
})
