import Cookies from 'js-cookie'
import router from '@/router'

export default function auth({
  next,
  router
}) {
  if (!Cookies.get('mxTkn')) {
    return next({
      name: 'Index'
    })
  }

  return next();
}
