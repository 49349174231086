import axios from 'axios'
import store from '@/store'

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

export default function setup() {
  axios.interceptors.request.use((config) => {
    const token = store.state.access_token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    config.params = {...config.params, lang: `${store.state.language}`}
    return config;
  }, (err) => {
    throw new Error('Something went wrong, please try again later')
    // throw new Error(this.$t('bo.somethingWentWrong'))
  });

  axios.interceptors.response.use((response) => {
    if (response.data.code === 400) {
      return response;
    } else if (response.data.code === 401) {
      // redirect to login page when response status is 401
      store.commit('setUserLogout')
    } else if (response.data.code != 200) {
      return response;
    } else {
      return response;
    }
  }, (err) => {
    throw new Error('Something went wrong, please try again later')
    // throw new Error(this.$t('bo.somethingWentWrong'))
  });
}
