<template>
  <div class="">
    <CDropdown variant="input-group">
      <CDropdownToggle style="border-color:transparent;" placement="bottom-end" class="p-0" :caret="false">
        <CAvatar v-if="user_data.role_id !== 0" size="md">
          <CIcon icon="cil-user" />
        </CAvatar>
        <CAvatar v-else size="md" color="secondary" text-color="white">
          <CIcon icon="cil-user" />
        </CAvatar>
      </CDropdownToggle>
      <CDropdownMenu class="pt-0">
        <div class="dropdown-area">
          <h1 class="dropdown-text">{{ user_data.username }}</h1>
          <h2 class="dropdown-text">{{ user_data.email }}</h2>
        </div>

        <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
          {{ $t('bo.account') }}
        </CDropdownHeader>
        <!-- <router-link class="dropdown-item" to="/change-wallet">
          <CIcon icon="cil-wallet" />
          {{ $t('bo.walletAddress') }}
        </router-link> -->
        <router-link class="dropdown-item" to="/change-pwd">
          <CIcon icon="cil-lock-locked" />
          {{ $t('bo.changePassword') }}
        </router-link>
        <!-- <a class="dropdown-item" href="#" target="_blank">
          <CIcon icon="cil-speech" /> {{ $t('bo.helpCenter') }}
        </a> -->
        <CDropdownDivider />
        <router-link class="dropdown-item text-center" to="/auth/logout">
          {{ $t('bo.logout') }}
        </router-link>
      </CDropdownMenu>
    </CDropdown>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore, mapState } from 'vuex'
import avatar from '@/assets/images/avatars/8.jpg'
export default {
  name: 'AppHeaderDropdownAccnt',
  setup() {
    const store = useStore()
    return {
      avatar: avatar,
      itemsCount: 42,
      user_data: computed(() => store.state.user_data),
    }
  },
  computed: {
    ...mapState([
      'user_data',
    ]),
  }
}
</script>
