<template>
  <div class="page-loader">
    <div class="spinner"></div>
  </div>
</template>

<script>
export default {
  name: 'AppLoader',
}
</script>
