import {
  h,
  resolveComponent
} from 'vue'
import {
  createRouter,
  createWebHashHistory,
  createWebHistory
} from 'vue-router'
import auth from '@/middleware/auth';
import guest from '@/middleware/guest';
import middlewarePipeline from "@/middleware/middleware-pipeline";

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [{
      path: '/',
      name: 'Home',
      component: DefaultLayout,
      children: [{
        path: '/',
        name: 'Index',
        component: () => import('@/views/index.vue'),
        meta: {
          middleware: [guest]
        },
      },
      {
        path: '/auth/login',
        name: 'Login',
        component: () => import('@/views/auth/login.vue'),
        meta: {
          middleware: [guest]
        },
      },
      {
        path: '/auth/register',
        name: 'Register',
        component: () => import('@/views/auth/register.vue'),
        meta: {
          middleware: [guest]
        },
      },
      {
        path: '/auth/logout',
        name: 'Logout',
        component: () => import('@/views/auth/logout.vue'),
      },
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@/views/Dashboard.vue'),
        meta: {
          middleware: [auth]
        },
      },
      {
        path: '/investment',
        name: 'Investment',
        component: () => import('@/views/investment'),
        meta: {
          middleware: [auth]
        },
      },
      {
        path: '/deposit',
        name: 'Deposit',
        component: () => import('@/views/investment/deposit.vue'),
        meta: {
          middleware: [auth]
        },
      },
      {
        path: '/sell',
        name: 'Sell',
        component: () => import('@/views/investment/sell.vue'),
        meta: {
          middleware: [auth]
        },
      },
      {
        path: '/change-pwd',
        name: 'Change Password',
        component: () => import('@/views/profile/changePwd.vue'),
        meta: {
          middleware: [auth]
        },
      },
      // {
      //   path: '/change-wallet',
      //   name: 'Change Wallet',
      //   component: () => import('@/views/profile/changeWallet.vue'),
      //   meta: {
      //     middleware: [auth]
      //   },
      // },
      {
        path: '/notification',
        name: 'User Notification',
        component: () => import('@/views/notification.vue'),
        meta: {
          middleware: [auth]
        },
      },
      {
        path: '/user',
        name: 'User',
        component: () => import('@/views/user'),
        meta: {
          middleware: [auth]
        },
      },
      {
        path: '/admin/deposit-list',
        name: 'Deposit List',
        code: 'depositList',
        component: () => import('@/views/transactionAdmin/depositList.vue'),
        meta: {
          middleware: [auth]
        },
      },
      {
        path: '/admin/withdraw-list',
        name: 'Withdraw List',
        code: 'withdrawList',
        component: () => import('@/views/transactionAdmin/withdrawList.vue'),
        meta: {
          middleware: [auth]
        },
      },
      {
        path: '/admin/roi-list',
        name: 'Roi List',
        code: 'roi',
        component: () => import('@/views/transactionAdmin/roiList.vue'),
        meta: {
          middleware: [auth]
        },
      },
      {
        path: '/admin/recycle-bin',
        name: 'RecycleBin',
        code: 'recycleBin',
        component: () => import('@/views/transactionAdmin/recycleBin.vue'),
        meta: {
          middleware: [auth]
        },
      },
      {
        path: '/admin/report',
        name: 'AdminReport',
        code: 'adminReport',
        component: () => import('@/views/report/admin.vue'),
        meta: {
          middleware: [auth]
        },
      },
      {
        path: '/report',
        name: 'Report',
        code: 'report',
        component: () => import('@/views/report/index.vue'),
        meta: {
          middleware: [auth]
        },
      },
      {
        path: '/group-incentives',
        name: 'UnclaimedBonus',
        code: 'groupIncentives',
        component: () => import('@/views/report/unclaimed-bonus.vue'),
        meta: {
          middleware: [auth]
        },
      },
      {
        path: '/admin/mlm',
        name: 'MLM',
        code: 'mlm',
        component: () => import('@/views/report/mlm.vue'),
        meta: {
          middleware: [auth]
        },
      },
      {
        path: "/:pathMatch(.*)*",
        name: '404',
        component: () => import('@/views/pages/Page404.vue'),
      },
      {
        path: '/system',
        name: 'System',
        children: [
          // for admin role
          {
            path: 'setting',
            name: 'Setting',
            component: () => import('@/views/system/setting'),
            meta: {
              middleware: [auth]
            },
          },
          {
            path: 'notification',
            name: 'System Notification',
            component: () => import('@/views/system/notification'),
            meta: {
              middleware: [auth]
            },
          },
          {
            path: 'permission',
            name: 'System Permission',
            component: () => import('@/views/system/permission'),
            meta: {
              middleware: [auth]
            },
          },
          {
            path: 'tier',
            name: 'Tier',
            component: () => import('@/views/system/tier'),
            meta: {
              middleware: [auth]
            },
          },
          {
            path: 'contract',
            name: 'System Contract',
            component: () => import('@/views/system/contract'),
            meta: {
              middleware: [auth]
            },
          },
        ],
      },
    ],
  },
  {
    path: '/verify-account',
    name: 'Verify Account',
    component: () => import('@/views/auth/verify-account.vue'),
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return {
      top: 0
    }
  },
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth'
      };
    }
    return { x: 0, y: 0 };  // Go to the top of the page if no hash
  },
})

router.beforeEach((to, from, next) => {

  /** Navigate to next if middleware is not applied */
  if (!to.meta.middleware) {
    return next()
  }

  const middleware = to.meta.middleware;
  const context = {
    to,
    from,
    next
  }

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1)
  })
})

export default router
