<template>
  <CSidebar position="fixed" :unfoldable="sidebarUnfoldable" :visible="sidebarVisible" @visible-change="
    (event) =>
      $store.commit({
        type: 'updateSidebarVisible',
        value: event,
      })
  ">
    <CSidebarBrand>
      <CImage custom-class-name="sidebar-brand-full" width="120" :src="require('@/assets/images/logo@2x.png')" />
      <CIcon custom-class-name="sidebar-brand-narrow" :icon="sygnet" :height="35" />
    </CSidebarBrand>
    <AppSidebarNav />
    <CSidebarToggler class="d-none d-lg-flex" @click="$store.commit('toggleSidebar')" />
  </CSidebar>
</template>

<script>
import { computed } from 'vue'
import { useStore, mapState } from 'vuex'
import {
  useToast
} from "vue-toastification"
import { AppSidebarNav } from './AppSidebarNav'
import { logoNegative } from '@/assets/brand/logo-negative'
import { sygnet } from '@/assets/brand/sygnet'
export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
  },
  setup() {
    const store = useStore()
    return {
      logoNegative,
      sygnet,
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
    }
  },
  data() {
    return {
      toast: useToast(),
      claim_load: false,
    }
  },
  computed: {
    ...mapState([
      'role',
    ]),
  },
  methods: {
  }
}
</script>
