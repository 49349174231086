import Cookies from 'js-cookie'
import router from '@/router'

export default function guest({
  next,
  router
}) {
  if (Cookies.get('mxTkn')) {
    return next({
      name: 'Dashboard'
    })

  }

  return next();
}
