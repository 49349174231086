import { createI18n } from 'vue-i18n'
import us from "./locales/us";
import cn from "./locales/cn";
import vn from "./locales/vn";

export default createI18n({
  legacy: false,
  globalInjection: true,
  locale: process.env.VUE_APP_I18N_LOCALE || "us",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "us",
  messages: { us, vn, cn },
})
