export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    code: 'dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
    visible: true,
  },
  {
    component: 'CNavItem',
    name: 'Submission',
    code: 'submission',
    to: '/admin/deposit-list',
    icon: 'cil-money',
    visible: true,
  },

  {
    component: 'CNavItem',
    name: 'Roi',
    code: 'roi',
    to: '/admin/roi-list',
    icon: 'cil-money',
    visible: true,
  },
  {
    component: 'CNavItem',
    name: 'User List',
    code: 'user',
    to: '/user',
    icon: 'cil-people',
    visible: true,
  },
  {
    component: 'CNavItem',
    name: 'MLM',
    code: 'mlm',
    to: '/admin/mlm',
    icon: 'cil-sitemap',
    visible: true,
  },
  {
    component: 'CNavItem',
    name: 'Investment',
    code: 'investment',
    to: '/investment',
    icon: 'cil-graph',
    visible: true,
  },

  {
    component: 'CNavItem',
    name: 'AdminReport',
    code: 'adminReport',
    to: '/admin/report',
    icon: 'cil-calculator',
    visible: true,
  },

  {
    component: 'CNavItem',
    name: 'Report',
    code: 'report',
    to: '/report',
    icon: 'cil-calculator',
    visible: true,
  },
  {
    component: 'CNavItem',
    name: 'GroupIncentives',
    code: 'groupIncentives',
    to: '/group-incentives',
    icon: 'cil-calculator',
    visible: true,
  },

  {
    component: 'CNavTitle',
    name: 'System',
    code: 'system',
    visible: true,
  },
  {
    component: 'CNavItem',
    name: 'Admin Notification',
    code: 'notification',
    to: '/system/notification',
    icon: 'cil-settings',
    visible: true,
  },

  {
    component: 'CNavItem',
    name: 'Recycle Bin',
    code: 'recycleBin',
    to: '/admin/recycle-bin',
    icon: 'cil-money',
    visible: true,
  },
  {
    component: 'CNavItem',
    name: 'Setting',
    code: 'setting',
    to: '/system/setting',
    icon: 'cil-settings',
    visible: true,
  },
]
