import axios from 'axios'

export function permissionList(payload) {
  return axios.get("api/individual/permission", { params: payload})
}

export function permission(payload) {
  return axios.get("api/individual/role-permission", { params: payload})
}

export function addPermission(payload) {
  return axios.post("api/individual/role-permission", payload)
}
