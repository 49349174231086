<template>
  <CFooter>
    <div>
      <span class="ms-1">
        {{ $t('bo.copyrightText') }}
      </span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
