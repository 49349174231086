import axios from 'axios'

export function me() {
  return axios.get("api/individual/info")
}

export function balanceHistory() {
  return axios.get("api/individual/balance-history")
}

export function updateMe(payload) {
  return axios.put("api/individual/update-profile", payload)
}
