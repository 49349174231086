import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toast from "vue-toastification"
import axios from './plugins/axios'
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import "/node_modules/flag-icons/css/flag-icons.min.css";
// Mixin For Global Function
import Mixin from './plugins/mixins'
import Landing from './plugins/mixins/landing'
import moment from "moment"

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import i18n from './i18n'
import BootstrapVue3 from 'bootstrap-vue-3'
import ElementUI from 'element-plus'
import 'element-plus/dist/index.css'
import mitt from 'mitt';
const emitter = mitt();


const options = {
  // You can set your default options here
};

const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(Toast, options);
app.use(axios)
app.use(moment)
app.use(i18n)
app.use(BootstrapVue3)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.mixin(Mixin)
app.mixin(Landing)
app.use(ElementUI)

app.config.globalProperties.emitter = emitter;

app.mount('#app')
