<template>
  <CHeader position="sticky" style="background: #181818;" class="mb-4">
    <CContainer fluid>
      <CImage custom-class-name="sidebar-brand-full" width="120" :src="require('@/assets/images/logo0.png')"/>
    </CContainer>
  </CHeader>
</template>

<script>
export default {
  name: 'AppHeader',
  setup() {
    return {

    }
  },
}
</script>
